import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/layouts/docs.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Honeyswap is a fork of Uniswap V2 deployed on xDai. The core code has not been modified, helper contracts have been deployed and maintainer fee has been enabled and directed to a contract which converts fees into Honey and deposits into the 1hive common pool.`}</p>
    <p>{`You can find full documentation of the code and information about the deployment in the `}<a parentName="p" {...{
        "href": "/docs/honeyswap"
      }}>{`honeyswap documentation`}</a>{`.`}</p>
    <p>{`Exchange: `}<a parentName="p" {...{
        "href": "https://honeyswap.org"
      }}>{`https://honeyswap.org`}</a></p>
    <p>{`Analytics: `}<a parentName="p" {...{
        "href": "https://info.honeyswap.org"
      }}>{`https://info.honeyswap.org`}</a></p>
    <p>{`Liquidity Incentives: Soon`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      